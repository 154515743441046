<template>
  <v-row>
    <v-col v-if="loadingAccounts">
      <mf-loading-dialog :loading="loadingAccounts"></mf-loading-dialog>
    </v-col>
    <v-col v-else cols="12">
      <v-row>
        <v-col cols="12">
          <mf-card-container title="Dados Gerais" description="Edição de usuário">
            <v-row>
              <v-col class="offset-1" cols="10">
                <v-row>
                  <v-col cols="6">
                    <mf-text-input v-model="name" disabled label="Nome" />
                  </v-col>
                  <v-col cols="6">
                    <mf-text-input v-model="login" disabled label="Login" />
                  </v-col>
                  <v-col v-if="!isInternal" cols="6">
                    <v-autocomplete
                      v-model="accounts"
                      label="Contas"
                      :items="accountsList"
                      item-text="name"
                      item-value="_id"
                      outlined
                      :multiple="multiUser"
                      @blur="getUnits"
                    >
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.name }}</span>

                        <span v-if="index === 1" class="grey--text text-caption">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on"> (+{{ accounts.length - 1 }} contas selecionadas)</span>
                            </template>
                            <span v-html-safe="getSelectedAccounts()"></span>
                          </v-tooltip>
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="isInternal" cols="auto">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <mf-toggle v-model="mf" class="mx-4" color="#334A58" value label="Admin Plataforma" />
                        </div>
                      </template>
                      <span>Garante ao usuário acesso a todas as contas da plataforma.</span>
                    </v-tooltip>
                  </v-col>
                  <v-col v-else cols="auto">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <mf-toggle v-model="multiUser" class="mx-4" color="#334A58" value label="Multi-user" @change="removeAccounts" />
                        </div>
                      </template>
                      <span>
                        Habilitando este campo, o usuário pode acessar várias plataformas. Desativá-lo removerá as outras<br />
                        contas, considerando apenas a primeira conta selecionada.
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="!isInternal">
              <v-col cols="12">
                <v-row justify="end">
                  <v-col cols="auto">
                    <mf-button label="Redefinir senha" @click="triggerAction"></mf-button>
                  </v-col>
                  <v-col cols="auto">
                    <mf-button label="Copiar configuração de usuário" @click="copyDialog = true"></mf-button>
                  </v-col>
                  <v-col cols="auto">
                    <mf-select-units-dialog :selected-units.sync="selectedUnits" :units="listUnits" template-type="button"> </mf-select-units-dialog>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </mf-card-container>
        </v-col>
        <v-col v-if="!isInternal" cols="12"
          ><mf-card-container title="Lojas configuradas">
            <v-row>
              <v-col>
                <v-data-table
                  :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
                  :options.sync="options"
                  :headers="headers"
                  :items="listUnitsSelected"
                ></v-data-table>
              </v-col>
            </v-row> </mf-card-container
        ></v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-row justify="start">
            <v-col class="mt-3" cols="auto">
              <mf-button label="Voltar" text color="error" @click="$router.push(`/usuarios`)"></mf-button>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row justify="end">
            <v-col cols="auto">
              <mf-action-buttons
                :primary-button="{
                  text: 'Salvar',
                  action: save,
                  isVisible: true,
                  isLoading: loading
                }"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <copy-from-user :id="id" :dialog="copyDialog" :force-push.sync="forcePush" @close="copyDialog = false" />
  </v-row>
</template>
<script>
import { QUERY_GET_INTERNAL_USER, QUERY_GET_ACCOUNTS_FOR_USERS, MUTATION_ADD_UNITS, MUTATION_UPDATE_USER } from '@/modules/users/graphql'
import { MUTATION_TRIGGER_ADMIN_ACTION } from '@/modules/session/graphql'
import { QUERY_GET_UNITS_BY_ACCOUNTS } from '@/modules/accounts/graphql'
export default {
  components: {
    CopyFromUser: () => import('@/modules/users/components/dialogs/CopyFromUser')
  },
  data: () => ({
    id: '',
    login: '',
    name: '',
    active: false,
    mf: false,
    password: '',
    multiUser: false,
    counter: 0,
    accounts: [],
    units: [],
    accountsList: [],
    loading: false,
    selectedUnits: [],
    copyDialog: false,
    options: {},
    userId: '',
    headers: [
      {
        text: 'ID',
        value: '_id',
        align: 'center',
        sortable: false
      },
      {
        text: 'Nome da Conta',
        value: 'name_account',
        align: 'center',
        sortable: false
      },
      {
        text: 'Tipo loja',
        value: 'type',
        align: 'center',
        sortable: false
      },
      {
        text: 'Id ERP',
        value: 'id_erp',
        align: 'center'
      },
      {
        text: 'Nome da Loja',
        value: 'name',
        align: 'center'
      }
    ],
    forcePush: false
  }),
  computed: {
    listUnitsSelected() {
      let result = []
      if (this.units && this.selectedUnits) {
        result = this.units
          .filter(unit => this.selectedUnits.includes(unit.id_erp))
          .map(unit => {
            return {
              ...unit,
              id_erp: unit.id_erp.split('::')[0],
              name_account: this.accountsList.filter(account => account._id === unit.account)[0]?.name,
              type: this.accountsList.filter(account => account._id === unit.account)[0]?.is_franchise ? 'Franquia' : '-'
            }
          })
      }
      return result
    },
    isInternal() {
      if (this.login) {
        return this.login.split('@')[1] === 'mercafacil.com'
      }
      return false
    },
    payloadSaveUser() {
      let result = Object.assign(
        {},
        { id: this.userId },
        { mf: this.mf },
        { multi_user: this.multiUser },
        { active: true },
        { id_account: typeof this.accounts === 'object' ? this.accounts[0] : this.accounts },
        { accounts: typeof this.accounts === 'object' ? this.accounts : [this.accounts] }
      )
      if (this.isInternal) {
        delete result.id_account
        delete result.accounts
      }
      if (!this.multiUser) {
        delete result.accounts
      }
      return result
    },
    loadingAccounts() {
      return this.accountsList.length === 0
    },
    listUnits() {
      return this.units.map(unit => {
        return { ...unit, name: this.accountsList.filter(acc => acc._id === unit.account)[0]?.is_franchise ? `[REDE] ${unit.name}` : unit.name }
      })
    }
  },
  apollo: {
    singleInternalUser: {
      query: QUERY_GET_INTERNAL_USER,
      fetchPolicy: 'no-cache',
      variables() {
        return { id: this.id }
      },
      skip() {
        return this.id === ''
      },
      update({ singleInternalUser }) {
        this.setData(singleInternalUser)
      }
    },
    availableAccounts: {
      query: QUERY_GET_ACCOUNTS_FOR_USERS,
      fetchPolicy: 'no-cache',
      variables() {
        return { input: {} }
      },
      update({ getAccountsForUsers }) {
        this.accountsList = getAccountsForUsers
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['accounts']
        }
      }
    }
  },
  mounted() {
    this.id = this.$route.params.id
  },
  methods: {
    removeAccounts() {
      this.accounts = typeof this.accounts === 'object' && this.accounts.length >= 1 ? this.accounts[0] : [this.accounts]
    },
    async getUnits() {
      const variables = {
        accounts: typeof this.accounts === 'object' ? this.accounts.join(',') : this.accounts
      }
      const { data } = await this.$apollo.query({
        query: QUERY_GET_UNITS_BY_ACCOUNTS,
        fetchPolicy: 'no-cache',
        variables,
        context: {
          uri: this.$microservicesUrls['crm']
        }
      })

      this.units = data.getUnitsByAccounts.map(i => {
        return {
          _id: i._id,
          id_erp: i.id_erp + '::' + i.account,
          name: i.name,
          account: i.account
        }
      })
    },
    async triggerAction() {
      const confirmation = await this.$confirm({
        title: 'Redefinir a senha',
        message: 'Deseja redefinir a senha? Nova senha será <strong>M3rc@f4cil123</strong>',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TRIGGER_ADMIN_ACTION,
          variables: {
            user_id: [this.userId],
            command: 'reset_password'
          }
        })
        this.$alert({
          alert_message: `Senha redefinida com sucesso.`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.forcePush = true
        this.$router.go()
      } catch (err) {
        console.log(err)
        this.$alert({
          alert_message: 'Falha ao redefinir senha.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    setData(user) {
      this.userId = user?.id_user
      this.login = user?.email
      this.name = user?.name
      this.active = user?.active
      this.mf = user?.permissions?.crm?.roles ? user?.permissions?.crm?.roles.includes('mercafacilAdmin') : false
      this.multiUser = user?.permissions?.crm?.multiUser
      if (user?.accounts) {
        this.accounts = this.multiUser ? user?.accounts.map(i => i.id) : user?.accounts[0]?.id
      }
      this.selectedUnits = user?.permissions?.stores.map(i => {
        return i.id + '::' + i.accountId
      })
      if (this.accounts?.length > 0) this.getUnits()
    },
    getSelectedAccounts() {
      const accountsSelected = this.accountsList.filter(i => {
        return this.accounts.includes(i._id)
      })
      let result = 'contas selecionadas: '
      accountsSelected.forEach(i => {
        result += `<br> - ${i.name}`
      })
      return result
    },
    async save() {
      this.loading = true
      try {
        await this.$apollo
          .mutate({
            mutation: MUTATION_UPDATE_USER,
            variables: this.payloadSaveUser
          })
          .then(async () => {
            const units = this.listUnitsSelected.map(unit => {
              return {
                idErp: unit.id_erp,
                accountId: unit.account
              }
            })
            await this.$apollo.mutate({
              mutation: MUTATION_ADD_UNITS,
              variables: {
                email: this.login,
                units: units
              }
            })
            this.$alert({
              alert_message: `Usuário atualizado com sucesso.`,
              alert_title: 'Sucesso!',
              alert_color: 'success',
              alert_icon: 'mdi-check-circle'
            })
            this.loading = false
            this.forcePush = true
            setTimeout(() => {
              this.$router.go()
            }, 1000)
          })
      } catch (err) {
        console.log(err.message)
        this.loading = false
        this.$alert({
          alert_message: 'Falha ao atualizar usuário.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.forcePush) {
      this.$confirm({
        title: 'Tem certeza?',
        message: 'Sua edição de usuário ainda não foi salva e todas as suas alterações recentes serão perdidas ao sair dessa página.',
        confirmText: 'FICAR E CONTINUAR EDIÇÂO',
        cancelText: 'SAIR DA TELA'
      })
        .then(() => {
          next(false)
        })
        .catch(() => {
          next()
        })
    } else {
      next()
    }
  }
}
</script>

<style scoped>
.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: auto !important;
}
</style>
