import gql from 'graphql-tag'

export const QUERY_GET_INTERNAL_USERS = gql`
  query($mf: Boolean, $active: Boolean, $role: String) {
    adminUsers(mf: $mf, active: $active, role: $role) {
      _id
      login
      name
      active
      mf
      access {
        requested_platform_access
        has_platform_access
        requested_platform_access_at
        requested_admin_access
        has_admin_access
        requested_admin_access_at
        management {
          platform_access_given_by
          platform_access_given_at
          admin_access_given_by
          admin_access_given_at
        }
      }
      role
    }
  }
`
export const QUERY_GET_USER_SESSIONS = gql`
  query($user_id: ID!) {
    userSessions(user_id: $user_id) {
      _id
      session_id
      user_id
      data {
        id_user
        has_pii_access
        id_account
        db_name
      }
      token
      enabled
      updated_at
      created_at
      expires_at
      session
    }
  }
`
export const MUTATION_TRIGGER_ADMIN_ACTION = gql`
  mutation($user_id: [ID!], $command: String!) {
    adminTriggerCommand(user_id: $user_id, command: $command)
  }
`
export const MUTATION_DEFINE_ROLE = gql`
  mutation($user_id: [ID!], $role: AdminRoles) {
    adminDefineRole(user_id: $user_id, role: $role)
  }
`
